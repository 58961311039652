import React from 'react'
import HeaderBottomTwo from './header/HeaderBottomTwo'
import Breadcrumb from '../components/breadcrumb/Breadcrumb'
import { Container } from 'react-bootstrap'
import FooterOne from './footer/FooterOne'

export default function Cancellation() {
    return (
        <div>
            <HeaderBottomTwo />
            <Breadcrumb
                breadcrumbBg={"https://png.pngtree.com/background/20211215/original/pngtree-agricultural-production-summer-solstice-farmer-rice-field-planting-photography-map-with-picture-image_1500018.jpg"}
                heading="Cancellation Policy"
                description="____________"
                currentPage="Cancellation Policy"
            />
            <Container className='mt-4'>
                <div>
                    <h3>Overview</h3>
                    <p>
                        At OSM Agri Mart, we aim to provide a seamless experience for both buyers and sellers. We understand that sometimes orders need to be canceled, and this policy outlines the conditions under which cancellations are permitted, as well as the process for requesting a cancellation.                    </p>
                </div>
                <div>
                    <h3>
                        Cancellation Eligibility

                    </h3>

                </div>

                <div>
                    <h3>1. Order Status:</h3>
                    <p>
                        <ul>
                            <li>
                                Orders can be canceled if they have not yet been shipped by the seller. Once the product has been dispatched, the order cannot be canceled and must go through the return process outlined in our refund policy.
                            </li>

                        </ul>
                    </p>
                </div>

                <div>
                    <h3>2. Timeframe:</h3>
                    <p>
                        <ul>
                            <li>
                                Buyers can cancel their order within 24 hours of placing it to receive a full refund.
                            </li>

                        </ul>
                    </p>
                </div>

                <h3>Cancellation Process</h3>
                <div>
                    <h3>1. Initiating a Cancellation:</h3>
                    <p>
                        <ul>
                            <li>
                                To cancel an order, buyers must contact our customer support team at osmagrimart@gmail.com as soon as possible. Please provide the order number and reason for cancellation.
                            </li>

                        </ul>
                    </p>
                </div>
                <div>
                    <h3>2. Review and Approval:</h3>
                    <p>
                        <ul>
                            <li>
                                Our team will review the cancellation request and respond within 1 business day. If the order has not been shipped, the cancellation will be approved, and a full refund will be processed.
                            </li>

                        </ul>
                    </p>
                </div>
                <div>
                    <h3>3. Refund Issuance:</h3>
                    <p>
                        <ul>
                            <li>
                                Once the cancellation is approved, the refund will be processed within 3-5 business days and credited back to the original payment method.
                            </li>

                        </ul>
                    </p>
                </div>

                <h3>Seller Cancellations</h3>
                <div>
                    <h3>1. Out of Stock:</h3>
                    <p>
                        <ul>
                            <li>
                                If a seller needs to cancel an order due to the product being out of stock or any other issue, they must notify the buyer and our customer support team immediately. The buyer will receive a full refund.
                            </li>

                        </ul>
                    </p>
                </div>
                <div>
                    <h3>2. Improper Listings:</h3>
                    <p>
                        <ul>
                            <li>
                                If an order needs to be canceled due to an incorrect listing or pricing error, the seller must inform the buyer and our customer support team. A full refund will be issued to the buyer.
                            </li>

                        </ul>
                    </p>
                </div>

            </Container>
            <FooterOne />

        </div>)
}
