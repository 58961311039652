import React from 'react'
import HeaderBottomTwo from './header/HeaderBottomTwo'
import FooterOne from './footer/FooterOne'
import { Col, Container, Row } from 'react-bootstrap'
import cancelImg from '../assets/images/cancel.png'

export default function PaymentFailed() {

    const currentDate = new Date();
    const date = currentDate.toDateString();
    const time = currentDate.toTimeString();

    const handleClick = () => {
        const timeout = setTimeout(() => {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.Farmerconnect'; // Fallback URL
          }, 1000);
      
          window.location.href = 'farmerconnect://'; // Custom URL scheme
      
          // Clear timeout if the app opens
          window.addEventListener('blur', () => {
            clearTimeout(timeout);
          });
    }


    return (
        <div>
            <HeaderBottomTwo />
            <Container className='mb-30 mt-30'>
                <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col sm="8">
                        <div style={{
                            border: "5px solid red",
                            borderRadius: "1rem",
                            padding: "20px"
                        }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <img src={cancelImg} alt='payment-success' width={100} />
                            </div>
                            <div className='mt-4' style={{ display: "flex", justifyContent: "center" }}>
                                <h4> Something went wrong !</h4>
                            </div>
                            <h6 style={{ textAlign: "center" }}>{date + time}</h6>
                            <div className='mt-2' >
                                <p style={{ textAlign: "center" }}>
                                    Your payment was unsuccessfull. <strong></strong>
                                </p>
                                <p style={{ textAlign: "center" }}>Need help? <br /> <a href="/contact">Contact our support team <br /> or<br /> mail us on osmagrimart@gmail.com</a>.</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <button className='l-btn' style={{background:"red"}} onClick={handleClick}>Continue</button>

                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
            <FooterOne />
        </div>
    )
}
