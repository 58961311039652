import React from 'react';
import { Link } from 'react-router-dom';
import FooterBG from '../../assets/images/footer-1.png';
import playstore from '../../assets/images/playstore1.png'
import appstore from '../../assets/images/appstore1.png'
import { FaPhoneAlt, FaFacebookF, FaTwitter, FaInstagram, FaPinterestP, FaDirections } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";


const FooterOne = () => {
    return (
        <>
            <footer>
                <div className="footer-top bg-property theme-bg-2 pt-60 pb-20" style={{ backgroundImage: `url(${FooterBG})` }}>
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-widget-1">
                                    <div className="footer-title">
                                        <h4>Contact Us</h4>
                                    </div>
                                    <div className="footer-text">
                                        <p>If you have any questions or need help, feel free to contact with our team.</p>
                                    </div>
                                    <div className="footer-text">
                                        <p>
                                            C/O AFCIPL D-104 MAHINDRA ASHVITHA
                                            HAFEEZ PET ROAD KPHB NEAR HITECH
                                            CITY MMTS KUKATPALLY, HYDERABAD
                                            TELANGANA - 500085
                                        </p>
                                    </div>
                                  
                                </div>
                            </div> */}
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-widget-2">
                                    <div className="footer-title">
                                        <h4>Quick Links</h4>
                                    </div>
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to="/contact">Contact Us</Link></li>
                                            {/* <li><Link to="/">Our Services</Link></li> */}
                                            {/* <li><Link to="/">Team</Link></li> */}
                                            {/* <li><Link to="/">Our Project</Link></li> */}
                                            <li><Link to="/about">About Us</Link></li>
                                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                            <li><Link to="/terms-condition">Terms & Condition</Link></li>
                                            <li><Link to="/refund-policy">Refund Policy</Link></li>
                                            <li><Link to="/cancellation-policy">Cancellation  Policy</Link></li>
                                            {/* <li><Link to="/">News Update</Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                <div className="footer-widget-3">
                                    <div className="footer-title">
                                        <h4>Our Services</h4>
                                    </div>
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to="/">Marketplace for Farmers</Link></li>
                                            <li><Link to="/">Transportation Service</Link></li>
                                            <li><Link to="/">Advertising Space</Link></li>
                                            <li><Link to="/">Labor and Workforce Solutions</Link></li>
                                            <li><Link to="/">Community Marketplace</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>



                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-widget-4">
                                    <div className="footer-title">
                                        <h4 style={{ paddingBottom: "30px" }}>Contact Us</h4>
                                        {/* <img style={{ height: "40px", width: "120px", borderRadius: "8px", cursor: "pointer" }} src={appstore} alt="" /><br />
                                        <img style={{ height: "40px", width: "130px", borderRadius: "8px", cursor: "pointer", marginTop: "10px" }} src={playstore} alt="" /> */}
                                        <div className="footer-text">
                                            <p>If you have any questions or need help, feel free to contact with our team.</p>
                                        </div>
                                        {/* <div className="footer-info">
                                        <Link to="/"><FaPhoneAlt /> (+91) 96016 15060</Link>
                                    </div> */}
                                        <div className="footer-text">
                                            <p>
                                            S.Y.NO 215/C, Ayodhaya X Road, Kandlakoya, Medchal, Ranga
                                            Reddy, Telangana, 501401
                                            </p>
                                        </div>
                                    </div>
                                    <div className="footer-text">
                                        <img src="" alt="" />
                                    </div>
                                    <div className="footer-social mt-10">
                                        <h5>Follow Us:</h5>
                                        <ul>
                                            <li><a href='https://www.facebook.com/profile.php?id=61561039397037' target='_blank'><FaFacebookF /></a></li>
                                            <li><a href="https://x.com/i/flow/login?redirect_after_login=%2FFarmerConnects" target='_blank'><FaXTwitter /></a></li>
                                            {/* <li><Link to="/"><FaInstagram /></Link></li> */}
                                            <li><a href="https://www.pinterest.co.uk/FarmerConnects/" target='_blank'><FaPinterestP /></a></li>
                                        </ul>
                                    </div>
                                    {/* <div className="footer-newsletter mt-40">
                                        <input type="text" placeholder="Email Address" />
                                        <button><FaAngleDoubleRight /></button>
                                    </div> */}
                                </div>
                            </div>





                            {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-widget-4">
                                    <div className="footer-title">
                                        <h4>Newsletter</h4>
                                    </div>
                                    <div className="footer-text">
                                        <p>Established since 2000. Mission Eland Landscaping Company in US.</p>
                                    </div>
                                    <div className="footer-social mt-35">
                                        <h5>Folllow Us:</h5>
                                        <ul>
                                            <li><Link to="/"><FaFacebookF /></Link></li>
                                            <li><Link to="/"><FaTwitter /></Link></li>
                                            <li><Link to="/"><FaInstagram /></Link></li>
                                            <li><Link to="/"><FaPinterestP /></Link></li>
                                        </ul>
                                    </div>
                                    <div className="footer-newsletter mt-40">
                                        <input type="text" placeholder="Email Address" />
                                        <button><FaAngleDoubleRight /></button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* <div className="footer-bottom theme-bg-1 pt-40 pb-30">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div className="copyright">
                                    <p>© 2022 <Link to="/">FMC</Link>. All right reserved.</p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-xl-end text-lg-end text-md-end text-center">
                                <div className="footer-menu">
                                    <ul>
                                        <li><Link to="/">About FMC</Link></li>
                                        <li><Link to="/">Careers</Link></li>
                                        <li><Link to="/">Privacy Policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </footer>
        </>
    )
}

export default FooterOne;