import React, { useEffect, useState } from 'react';
import MobileMenu from './MobileMenu';
import { Button } from 'react-bootstrap';
import Logo from '../../components/logo/Logo';
import Nav from './Nav';
import { Link, NavLink } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Weather from "../../components/weather"
import img1 from '../../assets/images/weather.png'
import poster from '../../assets/images/banner4.jpg'
import axios from 'axios';
import { MdCancel } from "react-icons/md";

function MyVerticallyCenteredModal(props) {






    return (
        <div className='weathermodel'>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >



                {/* </Modal.Header > */}
                <Modal.Body style={{ padding: "0px" }} >
                    <div onClick={props.onHide} style={{position:"absolute",right:"10px",top:"10px",cursor:"pointer"}}>
                        <MdCancel style={{color:"#ffffff",fontSize:"30px"}}/>
                    </div>
                    <Weather />
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer> */}
            </Modal>
        </div>

    );
}




const HeaderBottomThree = () => {


    const [weatherpopup, setWeatherPopUp] = useState(false)
    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [error, setError] = useState(null);
    const [temp, setTemp] = useState("")
    const getLocation = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const apiKey = "5f91081cc4043205b3afeb835cb4664e"
                    const resData = await axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${position.coords.latitude}&lon=${position.coords.longitude}&appid=${apiKey}&units=metric`)
                    console.log("res", resData?.data)
                    setTemp(resData?.data)
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                    setError(null);
                },
                (error) => {
                    setError(error.message);
                }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    };

    useEffect(() => {
        getLocation()
    }, [])
    console.log("location", location)


    return (
        <>
            <div className='HeaderBottomThree-div'>
                <div className='image-header'>
                    <img src={poster} alt="" />


                    <div className='main-header-div'>
                        <div className='main-logo-div'>
                            <Logo />
                        </div>
                        <div>
                            <div className="main-menu text-center">
                                <nav id="mobile-menu">
                                    <ul>
                                        <li>
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li><Link to="/about">About</Link></li>

                                        {/* <li><Link to="/gallery">Gallery</Link>

                                        </li> */}

                                        <li><Link to="/blog-list">Blog</Link>

                                        </li>
                                        <li><Link to="/contact">Contact Us</Link></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div style={{ paddingTop: "75px" }} className="mobile-menu">
                            <MobileMenu />
                        </div>

                    </div>





                    <div className="header-btn">
                        <Button onClick={() => setWeatherPopUp(true)} to="#" className="l-btn">
                            {temp?.main?.temp ?
                                <>{Math.round(temp?.main?.temp)}&#176;C</>
                                :

                                <img style={{ height: "28px", width: "35px", marginRight: "0" }} src={img1} alt="" />
                            }
                        </Button>
                        {/* <div className='pbmit-sticky-corner  pbmit-top-left-corner'>
                            <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30 35V0C30 16 16 30 0 30H30Z" fill="white" />
                            </svg>
                        </div>
                        <div className='pbmit-sticky-corner  pbmit-top-right-corner'>
                            <svg width="30" height="30" fill viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30 30V0C30 16 16 30 0 30H30Z" fill="white" />
                            </svg>
                        </div> */}
                    </div>
                </div>




                <div className='details-homepage' >
                    <h1 className='slider-description-head' style={{ color: "#ffffff" }}>Welcome to OSM Agri Mart</h1>
                    <p className='slider-description' style={{ color: "#ffffff" }}>Where Buyers Meet sellers for Fresh, Sustainable Food. Explore our platform to discover a diverse range of locally sourced produce, connect directly with farmers, and support sustainable agriculture practices.</p>
                    {/* <a href='/' className="l-btn">Our Solution</a> */}
                </div>




                <MyVerticallyCenteredModal
                    show={weatherpopup}
                    onHide={() => setWeatherPopUp(false)}
                />
            </div>

        </>
    )
}

export default HeaderBottomThree;










