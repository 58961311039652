import React from 'react'
import farmer from './../assets/images/farmer.png'
import buyer from './../assets/images/buyer.png'
import seller from './../assets/images/seller.png'
import { Grid } from '@mui/material'
import { Container } from 'react-bootstrap'

const Benefits = () => {
    return (
        <div className='benefits-divv'>
            <div className='b-div-1'>
                {/* <h2 data-aos="fade-down" className='pb-5 text-center'>Benefits</h2> */}
                <h2 className='pb-5 text-center'>Benefits</h2>
              

        <Container>


                <div className='b-div-3'>
                    <Grid container spacing={2}>
                        {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className='b-div-3_1'>
                                <div className='b-div-3_2'>
                                    <h3>For Farmers</h3>
                                    <img style={{ height: "80px", width: "80px" }} src={farmer} alt="" />
                                </div>
                                <ul>
                                    <li>Increased market access and higher profit margins by eliminating middlemen.</li>
                                    <li> Access to a broader range of buyers and better pricing options.</li>
                                    <li>Availability of essential agricultural inputs and equipment at competitive prices.</li>
                                </ul>

                            </div>
                        </Grid> */}
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>

                            <div className='b-div-3_1'>
                                <div className='b-div-3_2'>
                                    <h3>For Buyers</h3>
                                    <img style={{ height: "80px", width: "80px" }} src={buyer} alt="" />
                                </div>
                                <ul>
                                    <li>Assurance of fresh and quality produce directly from the source.</li>
                                    <li>Competitive pricing through direct negotiations and bulk buying options.</li>
                                    <li>Convenient and transparent purchasing process with reliable delivery.</li>
                                </ul>

                            </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>

                            <div className='b-div-3_1'>
                                <div className='b-div-3_2'>
                                    <h3>For Sellers</h3>
                                    <img style={{ height: "80px", width: "80px" }} src={seller} alt="" />
                                </div>
                                <ul>
                                    <li>Direct access to a large customer base of farmers.</li>
                                    <li>Opportunities to expand market reach and increase sales.</li>
                                    <li> Platform to educate farmers about new products and technologies</li>
                                </ul>

                            </div>
                        </Grid>
                    </Grid>
                </div>

                </Container>





            </div>
        </div>
    )
}

export default Benefits