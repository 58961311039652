import React from 'react'
import ethical from '../assets/images/how-it-works/enjoy-the-landscape.png'
import innovation from '../assets/images/how-it-works/innovation.png'
import susta from '../assets/images/how-it-works/start-planting.png'
import acceb from '../assets/images/how-it-works/preparation-phase.png'

const Whyfmc1 = () => {
    return (
        <div className='why_fmc-div'>
            <h2 data-aos="slide-down" className='mt-5 mb-5 text-center'> Why OSM Agri Mart ?</h2>
            <div className='why_fmc-div-1'>

                <div data-aos="slide-right" className='why_fmc-div-1_1'>
                    <span className='works-icon'>
                        <img style={{ backgroundColor: "green" }} src={ethical} alt="" />
                    </span>
                    <p>Ethical Sourcing</p>
                </div>
                <div data-aos="zoom-in-up" className='why_fmc-div-1_1'>
                    <span className='works-icon'>
                        <img style={{ backgroundColor: "green" }} src={innovation} alt="" />
                    </span>
                    <p>Innovation</p>
                </div>
                <div data-aos="zoom-in-up" className='why_fmc-div-1_1'>
                    <span className='works-icon'>
                        <img style={{ backgroundColor: "green" }} src={acceb} alt="" />
                    </span>
                    <p>Accessibility </p>
                </div>
                <div data-aos="slide-left" className='why_fmc-div-1_1'>
                    <span className='works-icon'>
                        <img style={{ backgroundColor: "green" }} src={susta} alt="" />
                    </span>
                    <p>Sustainability</p>
                </div>

            </div>
        </div>
    )
}

export default Whyfmc1